/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import starsBg from "../../images/blade-3-stars-tile.png"
import cloudBg1 from "../../images/clouds-01-test.png"
import cloudBg2 from "../../images/clouds-02-test.png"
import mountainFg from "../../images/blade-6-mountain-2.png"
import Scroller from "../scroller"
import tabletFg from "../../images/single-ipad-1.png"
import phoneFg from "../../images/single-iphone-2.png"
import AnimateChildren from "../animate-children"
import { useWindowScroll } from "react-use"
import { useInView } from "react-intersection-observer"
import {
  useMotionValue,
  motion,
  useSpring,
  useMotionTemplate,
} from "framer-motion"

const LargeCopy = ({ data }) => {
  const { ref, inView } = useInView({
    initialInView: false,
    threshold: 1,
  })
  const animationValue = useSpring(50, { damping: 20 })
  const animationValueDelayed = useSpring(50, { damping: 20 })
  const maskImageValue = useMotionTemplate`url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="50"><rect fill="black" height="50" width="100%" y="${animationValue}" x="0"/></svg>')`
  const maskImageValueDelayed = useMotionTemplate`url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="50"><rect fill="black" height="50" width="100%" y="${animationValueDelayed}" x="0"/></svg>')`

  React.useEffect(() => {
    if (inView) {
      animationValue.set(0)
      setTimeout(() => {
        animationValueDelayed.set(0)
      }, 1000)
    }
  }, [inView])
  return (
    <div
      ref={ref}
      sx={{
        fontSize: ["44px", "54px", "64px", "84px", "104px", "164px"],
        fontWeight: 500,
        lineHeight: "100%",
        mb: 0,
        pb: "10px",
        color: "#E3E3EB",
        "&>div": {
          maskSize: [
            "100% 44px",
            "100% 54px",
            "100% 64px",
            "100% 84px",
            "100% 104px",
            "100% 164px",
          ],
        },
      }}
    >
      <motion.div
        style={{ WebkitMaskImage: maskImageValue, maskImage: maskImageValue }}
      >
        <span
          sx={{
            backgroundImage: "linear-gradient(0deg, #b3c5f3, #8CADF9)",
            backgroundSize: "100%",
            "-webkit-background-clip": "text",
            "-moz-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            "-moz-text-fill-color": "transparent",
          }}
        >
          {renderLineBreak(data.largeCopy.firstLine)}
        </span>
      </motion.div>
      <motion.div
        style={{
          WebkitMaskImage: maskImageValueDelayed,
          maskImage: maskImageValueDelayed,
        }}
      >
        <span
          sx={{
            backgroundImage: "linear-gradient(0deg, #E3E3EB, #b3c5f3)",
            backgroundSize: "100%",
            "-webkit-background-clip": "text",
            "-moz-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            "-moz-text-fill-color": "transparent",
          }}
        >
          <span
            sx={{ width: [0, 0, 0, 0, "100px"], display: "inline-block" }}
          ></span>
          {renderLineBreak(data.largeCopy.secondLine)}
        </span>
      </motion.div>
    </div>
  )
}

const Quote = ({ quote, index, scrollY }) => {
  const ref = React.useRef(null)
  const shown = React.useMemo(() => {
    const offset =
      ref?.current &&
      ref.current.getBoundingClientRect().top -
        (window.innerHeight / 2 - ref.current.clientHeight / 2)
    return (
      ref?.current &&
      offset > -ref.current.clientHeight / 2 &&
      offset < ref.current.clientHeight / 2
    )
  }, [scrollY])
  return (
    <div
      ref={ref}
      sx={{
        ...(index % 2 === 1 && { ml: [0, 0, "50%"] }),
        width: ["100%", "100%", "50%"],
        maxWidth: "556px",
        height: "700px",
        display: "flex",
        flexDirecion: "column",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          position: "relative",
          transition: "1s",
          opacity: 0,
          ...(index % 2 === 1 ? { left: "200px" } : { right: "200px" }),
          ...(shown && {
            opacity: 1,
            ...(index % 2 === 1 ? { left: 0 } : { right: 0 }),
          }),
        }}
      >
        <span
          sx={{
            color: "#D4576E",
            fontSize: ["90px", "90px", "90px", "90px", "122px"],
            lineHeight: ["90px", "90px", "90px", "90px", "122px"],
            fontWeight: "bold",
            textAlign: "center",
            ml: "-3px",
          }}
        >
          “
        </span>
        <h4
          sx={{
            fontSize: ["34px", "34px", "25px", "25px", "34px"],
            lineHeight: "38px",
            mb: ["25px", "25px", "20px", "20px", "40px"],
            mt: ["-35px", "-35px", "-40px", "-40px", "-50px"],
          }}
        >
          {quote.title}
        </h4>
        <p
          sx={{
            fontFamily: "Source Code Pro",
            fontWeight: 600,
            fontSize: "14px",
            textTransform: "uppercase",
          }}
        >
          - {quote.subtitle}
        </p>
      </div>
    </div>
  )
}

const DeviceSlide = ({ data }) => {
  const { ref, inView } = useInView({
    initialInView: false,
    threshold: 0.4,
  })
  const animationValue = useSpring(0, { damping: 50 })
  const blurValue = useMotionTemplate`blur(${animationValue}px)`
  React.useEffect(() => {
    if (inView) {
      setTimeout(() => animationValue.set(5), 1500)
    }
  }, [inView])
  return (
    <div ref={ref}>
      <motion.div
        style={{ backdropFilter: blurValue, WebkitBackdropFilter: blurValue }}
      >
        <Slide
          sx={{
            minHeight: "100vh",
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              py: "50px",
              flexGrow: 1,
              color: "#E3E3EB",
              position: "relative",
            }}
          >
            <AnimateChildren
              visibleStyle={{ opacity: 1 }}
              invisibleStyle={{ opacity: 0 }}
              transition={{ duration: 1 }}
              sx={{ position: "relative", height: "100%" }}
              initialDelay={2.5}
              delay={0}
              threshold={0}
              containerStyle={{
                maxWidth: [
                  "500px",
                  "500px",
                  "500px",
                  "500px",
                  "650px",
                  "750px",
                ],
              }}
            >
              <h2
                sx={{
                  fontSize: ["44px", "54px", "64px"],
                  fontWeight: 500,
                  lineHeight: "110%",
                }}
              >
                {data.deviceTitle}
              </h2>
            </AnimateChildren>
            <AnimateChildren
              visibleStyle={{ right: "-25%", opacity: 1 }}
              invisibleStyle={{ right: "-100%", opacity: 0 }}
              transition={{ duration: 1 }}
              initialDelay={3}
              delay={0}
              threshold={0}
              sx={{
                mt: ["30px", "30px", "30px", "30px", 0],
                mb: ["70px", "70px", "70px", "70px", 0],
                position: [
                  "relative",
                  "relative",
                  "relative",
                  "relative",
                  "absolute",
                ],
                top: [0, 0, 0, 0, "-100px"],
              }}
            >
              <img
                sx={{
                  maxHeight: ["70vh", "70vh", "70vh", "70vh", "90vh", "100vh"],
                  objectFit: "contain",
                }}
                src={tabletFg}
              />
            </AnimateChildren>
            <AnimateChildren
              visibleStyle={{ top: "0px", opacity: 1 }}
              invisibleStyle={{ top: "100px", opacity: 0 }}
              transition={{ duration: 0.7 }}
              initialDelay={4}
              delay={0}
              threshold={0}
              sx={{ position: "relative" }}
              containerStyle={{
                display: ["none", "none", "none", "none", "block"],
                position: "absolute",
                left: [0, 0, 0, 0, 0, "-5%"],
                bottom: [0, 0, 0, 0, "-400px", "-300px"],
              }}
            >
              <img
                sx={{
                  height: [0, 0, 0, 0, "600px", "auto"],
                }}
                src={phoneFg}
              />
            </AnimateChildren>
            <AnimateChildren
              visibleStyle={{ opacity: 1 }}
              invisibleStyle={{ opacity: 0 }}
              transition={{ duration: 0.7 }}
              initialDelay={5}
              delay={0}
              threshold={0}
              sx={{ position: "relative" }}
            >
              <Scroller
                sx={{
                  position: "relative",
                  bottom: ["10px", "10px", "10px", "10px", "-20px"],
                  mt: "30px",
                }}
              >
                {data.deviceScroller}
              </Scroller>
            </AnimateChildren>
          </Container>
        </Slide>
      </motion.div>
    </div>
  )
}

const QuoteBlade = ({ data }) => {
  const ref = React.useRef(null)
  const { y: scrollY } = useWindowScroll()
  const offset =
    ref?.current &&
    (scrollY -
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop) /
      ref.current.clientHeight
  return (
    <div ref={ref} sx={{ position: "relative" }}>
      <AnimateChildren
        visibleStyle={{ opacity: 1 }}
        invisibleStyle={{ opacity: 0 }}
        transition={{ duration: 1 }}
        sx={{ position: "relative", height: "100%" }}
        initialDelay={2.5}
        delay={0}
        threshold={0}
        containerStyle={{
          position: "absolute",
          top: 0,
          zIndex: -1,
          width: "100%",
          height: "100%",
          background: "#171e2f",
        }}
      >
        <div
          sx={{
            backgroundImage: `url(${starsBg})`,
            backgroundSize: [
              "400px 400px",
              "400px 400px",
              "400px 400px",
              "500px 500px",
              "600px 600px",
              "700px 700px",
            ],
            backgroundAttachment: "fixed",
            backgroundPosition: `0px -${offset * 100}px`,
            width: "100%",
            height: "100%",
          }}
        ></div>
      </AnimateChildren>
      <div
        sx={{
          height: "90%",
          width: "100%",
          position: "absolute",
          bottom: 0,
          backgroundImage:
            "linear-gradient(0deg, #e3b5ae 10%,#e3b5ae 18%, #8087b6 30%, #3174b5 60%, transparent)",
        }}
      ></div>
      <div sx={{ position: "absolute", bottom: "-10px", width: "100%" }}>
        <img
          src={cloudBg2}
          sx={{
            width: "100%",
            mb: "0px",
            opacity: 0.5,
            position: "relative",
            top: ["-850px", "-600px", "-300px", "-100px", "-400px", "-100px"],
            transform: `translate3d(0,${offset * 400}px,0)`,
          }}
        />
        <img
          src={cloudBg1}
          sx={{
            width: "100%",
            mb: "-200px",
            opacity: 0.5,
            position: "relative",
            top: ["-750px", "-600px", "-300px", "-100px", "-400px", "-100px"],
            transform: `translate3d(0,${offset * 400}px,0)`,
          }}
        />
        <img
          src={mountainFg}
          sx={{
            width: "100%",
            minHeight: ["1600px", "1500px", "1800px", "1800px", "1800px"],
            objectFit: "cover",
            position: "relative",
            top: ["-200px", "-100px", "0px", "0px", "-200px"],
            transform: `translate3d(0,${offset * 100}px,0)`,
          }}
        />
      </div>
      <Slide
        sx={{
          minHeight: "100vh",
          py: "80px",
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: -1,
          }}
        >
          <div
            sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              backgroundImage:
                "linear-gradient(180deg, #171e2f 20%, transparent)",
            }}
          ></div>
        </div>
        <div
          sx={{
            px: "30px",
          }}
        >
          <LargeCopy data={data} />
        </div>
      </Slide>

      <Slide
        sx={{
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
            color: "#E3E3EB",
            py: "100px",
            mt: "-100px",
          }}
        >
          {data.quotes.map((quote, index) => (
            <Quote key={index} index={index} quote={quote} scrollY={scrollY} />
          ))}
        </Container>
      </Slide>
      <DeviceSlide data={data} />
    </div>
  )
}
export default QuoteBlade
