/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import mountainFg from "../../images/mountain- 2.png"

const TransformationBlade = ({ data }) => {
  return (
    <Slide
      sx={{
        minHeight: "100vh",
        width: "100%",
        position: "relative",
        backgroundImage: "linear-gradient(90deg, #f5e5f1 10%, #f6f5fa)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        sx={{
          height: "100%",
          py: "100px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          sx={{
            fontSize: "40px",
            lineHeight: "110%",
            width: ["100%", "100%", "100%", "80%"],
            maxWidth: "1048px",
            fontWeight: 500,
            mb: ["60px", "60px", "80px", "80px", "90px", "100px"],
          }}
        >
          {data.title}
        </h1>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "1fr",
              "1fr",
              "1fr 1fr",
              "1fr 1fr",
              "1fr 1fr 1fr",
            ],
            columnGap: "35px",
            rowGap: ["60px", "60px", "80px", "80px", "200px"],
          }}
        >
          {data.cards.map((card, index) => (
            <div
              key={index}
              sx={{
                p: "15px",
                pb: 0,
                position: "relative",
                height: "fit-content",
              }}
            >
              <div
                sx={{
                  borderTop: "2px solid #53C7B8",
                  borderLeft: "2px solid #53C7B8",
                  width: "22px",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              ></div>
              <div
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {card.highlight && (
                  <div
                    sx={{
                      fontSize: "64px",
                      lineHeight: "64px",
                      position: "relative",
                      top: "-10px",
                      mr: "14px",
                      color: "#53C7B8",
                      fontWeight: 500,
                    }}
                  >
                    {card.highlight}
                  </div>
                )}
                <div
                  sx={{
                    fontWeight: 500,
                    fontSize: "28px",
                    position: "relative",
                    strong: {
                      fontWeight: 500,
                      color: "#53C7B8",
                      fontStyle: "italic",
                    },
                  }}
                >
                  {card.title}
                </div>
              </div>
              <div sx={{ mt: "28px" }}>
                {card.isQuote && (
                  <span
                    sx={{
                      color: "#D4576E",
                      fontSize: "34px",
                      lineHeight: "60%",
                      fontWeight: "bold",
                      textAlign: "center",
                      mb: 0,
                    }}
                  >
                    “
                  </span>
                )}
                <div sx={{ fontSize: "22px" }}>{card.content}</div>
              </div>
              <img
                sx={{ position: "relative", top: "6px", mt: "20px" }}
                src={card.image}
              />
            </div>
          ))}
        </div>
      </Container>
      <div
        sx={{
          pt: "50px",
          mt: "-50px",
          backgroundImage:
            "linear-gradient(0deg, #171e2f 50%, #c7c6cd 60%, #c7c6cd 80%, transparent)",
          minHeight: "1400px",
          position: "relative",
        }}
      >
        <img
          src={mountainFg}
          sx={{
            position: "absolute",
            top: "50px",
            minHeight: "1400px",
            minWidth: "100%",
            objectFit: "cover",
          }}
        />
        <div
          sx={{
            position: "relative",
            height: "100%",
            minHeight: "1400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#E3E3EB",
          }}
        >
          <div sx={{ fontSize: "54px", mt: "280px" }}>
            {data.translationTitle}
          </div>
          <div
            sx={{
              flexGrow: 1,
              mt: "100px",
              py: "25px",
              px: "30px",
              width: "100%",
              backdropFilter: "blur(10px)",
              fontWeight: 500,
              fontSize: ["48px", "62px", "76px", "86px", "96px"],
              lineHeight: "100%",
              backgroundImage: "linear-gradient(0deg, #6f99f9, #E3E3EB)",
              backgroundSize: "100%",
              "-webkit-background-clip": "text",
              "-moz-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
              "-moz-text-fill-color": "transparent",
              strong: {
                fontWeight: 500,
                fontStyle: "italic",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {data.translationText}
          </div>
        </div>
      </div>
    </Slide>
  )
}
export default TransformationBlade
