/** @jsx jsx */
import { jsx } from "theme-ui"
import { useForm } from "react-hook-form"
import { renderLineBreak } from "../helpers"

const ContactForm = () => {
  const data = {
    title: "DON’T BE SHY. \nDROP US A NOTE",
    subtitle:
      "We’re here to help. And we always respond promptly. So what’s keeping you up at night? Reach out and let’s get started.",
    bottom: {
      title: "PROPANE",
      line1: "2501 Harrison Address Suite 5",
      line2: "San Francisco, CA 94110",
      phone: "415.550.8692",
      phoneLink: "tel:4155508692",
      directions: "Directions",
      directionsLink:
        "https://www.google.com/maps?daddr=37.757305,%20-122.411944",
    },
  }
  const { handleSubmit, register, errors, watch, setValue } = useForm({
    mode: "onBlur",
  })
  const onSubmit = data => {
    console.log(data)
  }
  const renderInput = (label, name, required, isEmail) => (
    <div
      sx={{
        mb: "40px",
      }}
    >
      <input
        placeholder=" "
        sx={{
          outline: 0,
          width: "100%",
          height: "52px",
          lineHeight: "52px",
          px: 0,
          border: 0,
          borderBottom: "1px solid white",
          color: "white",
          ...(errors[name] && { borderColor: "#aa0061" }),
          background: "transparent",
          fontSize: "17px",
          ":focus, :not(:placeholder-shown)": {
            "&+div": {
              top: "-37px",
              fontSize: "14px",
            },
          },
        }}
        name={name}
        ref={register({
          required,
          ...(isEmail && {
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }),
        })}
      />
      <div
        sx={{
          ...(errors[name] && { color: "#aa0061" }),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "52px",
          mt: "-52px",
          fontSize: "17px",
          position: "relative",
          top: 0,
          pointerEvents: "none",
          transition: "0.3s",
        }}
      >
        {label}
      </div>
    </div>
  )
  return (
    <div sx={{}}>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
          columnGap: "30px",
          color: "white",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1
              sx={{
                fontSize: ["55px", "55px", "45px", "55px", "68px"],
                letterSpacing: ["-1px", "-1px", "-1px", "-1px", "-2.72px"],
              }}
            >
              {renderLineBreak(data.title)}
            </h1>
            <div>{data.subtitle}</div>
          </div>
          <div
            sx={{
              mt: "20px",
              lineHeight: "29.75px",
              letterSpacing: "0.5px",
              a: {
                display: "block",
                color: "white",
              },
            }}
          >
            <div sx={{ fontWeight: 600 }}>{data.bottom.title}</div>
            <div>{data.bottom.line1}</div>
            <div>{data.bottom.line2}</div>
            <a
              sx={{ ":hover": { textDecoration: "underline" } }}
              target="_blank"
              href={data.bottom.phoneLink}
            >
              {data.bottom.phone}
            </a>
            <a
              sx={{
                mt: "25px",
                ":hover": {
                  span: {
                    left: "2px",
                  },
                },
              }}
              target="_blank"
              href={data.bottom.directionsLink}
            >
              {data.bottom.directions}
              <span
                sx={{
                  transform: "scaleX(.5) translateY(1px)",
                  display: "inline-block",
                  fontSize: "22px",
                  transition: "0.2s",
                  position: "relative",
                  left: 0,
                }}
              >
                {">"}
              </span>
            </a>
          </div>
        </div>
        <form
          sx={{
            mt: ["30px", "30px", 0],
            display: "flex",
            flexDirection: "column",
            fontSize: "17px",
            color: "white",
            mb: 0,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: ["1fr", "1fr 1fr"],
              columnGap: "30px",
              flexDirection: "row",
            }}
          >
            {renderInput("First Name", "firstName", true)}
            {renderInput("Last Name", "lastName", true)}
          </div>
          {renderInput("Email", "email", true, true)}
          {renderInput("Company (optional)", "company")}
          {renderInput("Phone (optional)", "phone")}
          {renderInput("Message (optional)", "message")}
          <div>
            {" "}
            <input
              sx={{
                border: 0,
                borderRadius: "24px",
                height: "46px",
                lineHeight: "46px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 500,
                textTransform: "uppercase",
                px: "35px",
              }}
              type="submit"
              value="Send it"
            />
          </div>
        </form>
      </div>
    </div>
  )
}
export default ContactForm
