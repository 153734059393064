/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import backBg from "../../images/blade-medical.jpg"

const CommitmentBlade = ({ data }) => {
  return (
    <Slide
      sx={{
        minHeight: "100vh",
        width: "100%",
        position: "relative",
        background: "#171e2f",
        display: "flex",
        flexDirection: "column",
        background: `url(${backBg})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <Container
        sx={{
          height: "100%",
          py: "20px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            fontSize: ["40px", "40px", "44px", "54px", "64px"],
            maxWidth: "986px",
            lineHeight: "110%",
            color: "#FAFAFA",
            fontWeight: 500,
            my: ["20px", "20px", 0],
            textShadow: "0 0 100px rgb(78 0 38)",
          }}
        >
          {renderLineBreak(data.title)}
        </div>
      </Container>
    </Slide>
  )
}
export default CommitmentBlade
