/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import mountainBgLines from "../../images/blade-hero-mountain-w-lines.png"
import mountainBgLinesTablet from "../../images/blade-hero-mountain-w-lines-t.png"
import mountainBgLinesMobile from "../../images/blade-hero-mountain-w-lines-m.png"
import Carousel from "../carousel"

const HeightsBlade = ({ data }) => {
  const renderFloatingWord = (word, x, y, gradientFrom, gradientTo) => (
    <div
      sx={{
        fontSize: "28px",
        lineHeight: "110%",
        color: "white",
        fontWeight: 500,
        position: "absolute",
        zIndex: 1,
        left: x.map(x => x + "%"),
        top: y + "%",
        backgroundImage: `linear-gradient(90deg, ${gradientFrom}, ${gradientTo})`,
        backgroundSize: "100%",
        "-webkit-background-clip": "text",
        "-moz-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        "-moz-text-fill-color": "transparent",
      }}
    >
      {word}
    </div>
  )
  const cardGradients = [
    ["#9a8fb1", "rgba(144, 124, 169, 0.7)"],
    ["#a18196", "rgba(161, 100, 147, 0.7)"],
    ["#bf9da9", "rgba(220, 135, 141, 0.7)"],
    ["#cba5a0", "rgba(233, 173, 155, 0.7)"],
  ]
  const renderCards = () =>
    data.cards.map((card, index) => (
      <div
        sx={{
          position: "relative",
          zIndex: 1,
          p: "25px",
          height: "620px",
          background: `linear-gradient(0deg, ${cardGradients[index][0]}, ${cardGradients[index][1]})`,
        }}
      >
        <div sx={{ fontSize: "84px", lineHeight: "84px", fontWeight: 400 }}>
          {index + 1}
        </div>
        <div
          sx={{
            mt: "10px",
            mb: "14px",
            fontSize: "34px",
            lineHeight: "110%",
            fontWeight: 500,
          }}
        >
          {card.title}
        </div>
        <div sx={{ fontSize: "22px" }}>{card.text}</div>
      </div>
    ))
  return (
    <React.Fragment>
      <Slide
        sx={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "black",
          color: "#171E2F",
        }}
      >
        <div sx={{ position: "relative", width: "100%", mb: "-30%" }}>
          <div
            sx={{
              background: `linear-gradient(0deg, #956f90 50%, #c59392 70%, #e0cbc4)`,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
            }}
          ></div>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              sx={{
                width: "100%",
                maxWidth: "1000px",
                position: "relative",
                zIndex: 1,
              }}
            >
              <div
                sx={{
                  fontSize: "34px",
                  fontWeight: 500,
                  mb: "80px",
                  mt: "120px",
                }}
              >
                {data.title1}
              </div>
              <div
                sx={{
                  fontSize: "34px",
                  fontWeight: 500,
                  width: "100%",
                  textAlign: "right",
                }}
              >
                {data.title2}
              </div>
            </div>
          </Container>
          <div sx={{ width: "100%", position: "relative" }}>
            {" "}
            <div
              sx={{
                backgroundImage: [
                  `url(${mountainBgLinesMobile})`,
                  `url(${mountainBgLinesMobile})`,
                  `url(${mountainBgLinesTablet})`,
                  `url(${mountainBgLines})`,
                ],
                width: "100%",
                height: "65vw",
                minHeight: "1540px",
                backgroundPosition: "top",
                backgroundSize: "cover",
                position: "relative",
                zIndex: 1,
              }}
            />
            {renderFloatingWord(
              data.words[0],
              [22, 22, 17, 25, 30, 35],
              6,
              "#bcafd5",
              "#9483bd"
            )}
            {renderFloatingWord(
              data.words[1],
              [29, 29, 22, 30, 33, 38],
              18,
              "#c99cb4",
              "#a4609b"
            )}
            {renderFloatingWord(
              data.words[2],
              [35, 35, 32, 35, 35, 40],
              30,
              "#e7bdc6",
              "#eb8b94"
            )}
            {renderFloatingWord(
              data.words[3],
              [44, 44, 40, 40, 40, 44],
              40,
              "#f4c7bd",
              "#fcbaa9"
            )}
          </div>
        </div>
        <div
          sx={{
            width: "100%",
            mt: ["-600px", "-600px", "-500px", "-300px", "-350px", "-100px"],
          }}
        >
          <div
            sx={{
              background: "black",
              maskImage:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 30%)",
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
            }}
          ></div>
          <div
            sx={{
              display: ["none", "none", "block"],
              fontSize: "68px",
              color: "#E3E3EB",
              fontWeight: 500,
              mb: "50px",
              position: "relative",
              zIndex: 1,
              width: "100%",
              textAlign: "center",
            }}
          >
            {data.subtitle}
          </div>
          <Container
            sx={{
              display: ["none", "none", "grid"],
              gridTemplateColumns: [
                "1fr 1fr",
                "1fr 1fr",
                "1fr 1fr",
                "1fr 1fr 1fr 1fr",
              ],
              gap: "3px",
              py: "20px",
              position: "relative",
            }}
          >
            {renderCards()}
          </Container>
          <div
            sx={{
              display: ["block", "block", "none"],
              position: "relative",
              zIndex: 1,
            }}
          >
            <Carousel type="heights">
              {renderCards().map(card => (
                <div sx={{ mr: "3px" }}>{card}</div>
              ))}
            </Carousel>
          </div>
        </div>
      </Slide>
    </React.Fragment>
  )
}
export default HeightsBlade
