/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import waterfallFg from "../../images/blade-waterfall.png"
import CTA from "../cta"

const FinalBlade = ({ data }) => {
  return (
    <div sx={{ position: "relative", color: "#171E2F" }}>
      <div
        sx={{
          position: "absolute",
          width: "100%",
          backgroundImage:
            "linear-gradient(180deg,#e5e4ed,transparent 20%), radial-gradient(circle at 100% 100%, #84dcfb, #fee9ec 70%)",
          height: "100%",
          zIndex: -1,
        }}
      ></div>
      <Slide
        sx={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            py: "30px",
          }}
        >
          <div
            sx={{
              flexGrow: 1,
              maxWidth: "1025px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <h2 sx={{ maxWidth: "900px" }}>{data.quotesTitle}</h2>
            <div>
              {data.quotes.map((quote, index) => (
                <div key={index}>
                  <div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img sx={{ borderRadius: "100%" }} src={quote.portrait} />
                  </div>
                  <div
                    sx={{
                      fontSize: "120px",
                      fontWeight: 600,
                      lineHeight: "120px",
                      mb: "-65px",
                      mt: "-40px",
                    }}
                  >
                    “
                  </div>
                  <h2
                    sx={{
                      fontSize: ["40px", "44px", "44px", "44px", "54px"],
                      textAlign: "center",
                    }}
                  >
                    {quote.quote}
                  </h2>
                  <div
                    sx={{
                      fontFamily: "Source Code Pro",
                      fontSize: "20px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      mt: "60px",
                    }}
                  >
                    <span
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      — {quote.name}
                    </span>{" "}
                    | {quote.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Slide>
      <Slide
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            py: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2
            sx={{
              fontSize: ["44px", "44px", "54px", "54px", "54px"],
              maxWidth: "1025px",
            }}
          >
            {data.scheduleTitle}
          </h2>
          <CTA
            sx={{
              mt: "50px",
              width: ["100%", "auto"],
              minWidth: ["unset", "300px"],
              maxWidth: ["unset", "300px"],
              fontSize: ["18px", "18px", "18px", "18px", "18px"],
              height: ["60px", "60px", "60px", "60px", "60px"],
            }}
            link={data.scheduleCtaLink}
          >
            {data.scheduleCtaText}
          </CTA>
          <div
            sx={{
              fontSize: "28px",
              fontWeight: 500,
              mt: "48px",
              maxWidth: "1025px",
            }}
          >
            {data.scheduleSubtitle}
          </div>
        </Container>
      </Slide>
      <Slide
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            py: "30px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2
            sx={{
              fontSize: ["44px", "44px", "54px", "54px", "54px"],
              maxWidth: "1025px",
            }}
          >
            {data.thankYouTitle}
          </h2>
          <img sx={{ mt: "40px", mb: "9px" }} src={data.thankYouIcon} />
          <div
            sx={{
              fontSize: "34px",
              lineHeight: "110%",
              fontWeight: 500,
              mt: "48px",
              maxWidth: "1025px",
            }}
          >
            {data.thankYouSubtitle}
          </div>
        </Container>
      </Slide>
    </div>
  )
}
export default FinalBlade
