/** @jsx jsx */
import { jsx } from "theme-ui"

function Slide({ children, ...restProps }) {
  return (
    <div sx={{ scrollSnapAlign: "start" }} {...restProps}>
      {children}
    </div>
  )
}
export default Slide
