/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import Scroller from "../scroller"

const OutcomeBlade = ({ data }) => {
  return (
    <React.Fragment>
      <Slide
        sx={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#10182b",
        }}
      >
        <Container
          sx={{
            display: "flex",
            py: "20px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            color: "#E3E3EB",
          }}
        >
          <h1
            sx={{
              fontSize: ["44px", "54px", "64px"],
              lineHeight: "110%",
              width: ["100%", "100%", "80%"],
              maxWidth: "1048px",
              fontWeight: 500,
              mb: 0,
              backgroundImage:
                "linear-gradient(180deg, #ececec,#ddcfe0, #f7e4c9)",
              backgroundSize: "100%",
              "-webkit-background-clip": "text",
              "-moz-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
              "-moz-text-fill-color": "transparent",
            }}
          >
            {renderLineBreak(data.title)}
          </h1>
          <div
            sx={{
              width: ["100%", "100%", "80%"],
              fontSize: ["30px", "34px"],
              lineHeight: "120%",
              mt: "57px",
            }}
          >
            {renderLineBreak(data.subtitle)}
          </div>
        </Container>
        <Scroller sx={{ mb: "20px", position: "relative" }}>
          {data.scroller}
        </Scroller>
      </Slide>
    </React.Fragment>
  )
}
export default OutcomeBlade
