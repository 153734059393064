/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import fgImage from "../../images/case-studies.png"
import bannerLogo from "../../images/logos-top/BH-logo.png"

const ProcessExplainedBlade = ({ data }) => {
  return (
    <div sx={{ position: "relative", color: "#171E2F" }}>
      <div
        sx={{
          width: "100%",
          position: "relative",
          backgroundColor: "#e4e4ec",
        }}
      >
        <div
          sx={{
            position: "absolute",
            width: "100%",
            backgroundImage:
              "linear-gradient(-45deg, #e09bbc, #d0dcf6 20%, transparent 40%), linear-gradient(45deg, #eae0f3, #f8f2f4, #f4d5e1 60%)",
            maskImage:
              "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
            height: "100%",
          }}
        ></div>
        <div sx={{ position: "relative", zIndex: 1 }}>
          <Slide
            sx={{
              minHeight: "100vh",
            }}
          >
            <Container
              sx={{
                py: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <h2
                sx={{
                  fontSize: ["44px", "54px", "44px", "54px", "64px"],
                  fontWeight: 500,
                  width: "100%",
                }}
              >
                {renderLineBreak(data.title)}
              </h2>
              <div
                sx={{
                  mt: "106px",
                  display: "grid",
                  gridTemplateColumns: [
                    "1fr",
                    "1fr 1fr",
                    "1fr 1fr",
                    "1fr 1fr 1fr 1fr",
                  ],
                  columnGap: "37px",
                  rowGap: "126px",
                }}
              >
                {data.cards.map((card, index) => (
                  <div key={index}>
                    <img src={card.icon} />
                    <div
                      sx={{
                        background: "#4C6195",
                        height: "2px",
                        width: "134px",
                        my: "17px",
                      }}
                    ></div>
                    <div sx={{ fontSize: "20px", fontWeight: 500 }}>
                      {card.text}
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </Slide>
          <Slide
            sx={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              sx={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Container
                sx={{
                  py: "30px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  sx={{
                    fontSize: "34px",
                    fontWeight: 500,
                    lineHeight: "110%",
                    maxWidth: "840px",
                  }}
                >
                  {data.secondTitle}
                </div>
                <div sx={{ display: "flex", width: "100%", maxWidth: "840px" }}>
                  <div
                    sx={{
                      display: "flex",
                      flexDirection: [
                        "column-reverse",
                        "column-reverse",
                        "column-reverse",
                        "row",
                      ],
                      alignItems: [
                        "flex-start",
                        "flex-start",
                        "flex-start",
                        "center",
                      ],
                      maxWidth: "840px",
                      width: "100%",
                    }}
                  >
                    <div
                      sx={{
                        minWidth: ["100%", "100%", "100%", "unset"],
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        sx={{
                          my: ["105px", "105px", "105px", 0],
                          mr: [0, 0, 0, "57px"],
                        }}
                      >
                        <img
                          src={bannerLogo}
                          sx={{ width: "auto", flexGrow: 0, height: "auto" }}
                        />
                        <div
                          sx={{
                            fontWeight: 500,
                            fontSize: "18px",
                            width: "250px",
                          }}
                        >
                          {data.logoCaption}
                        </div>
                      </div>
                    </div>
                    <img
                      sx={{
                        maxWidth: "unset",
                        mt: ["105px", "105px", "105px", 0],
                      }}
                      src={fgImage}
                    />
                  </div>
                </div>
              </Container>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  )
}
export default ProcessExplainedBlade
