/** @jsx jsx */
import { jsx } from "theme-ui"

function Scroller({ children, color, ...restProps }) {
  return (
    <div
      sx={{
        position: "absolute",
        bottom: 0,
        fontFamily: "source code pro",
        fontSize: "14px",
        lineHeight: "110%",
        color: color || "white",
        textTransform: "uppercase",
        fontWeight: 600,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        width: "100%",
      }}
      {...restProps}
    >
      <div
        sx={{
          width: ["80%", "100%"],
        }}
      >
        {children}
      </div>
      <div
        sx={{
          display: ["none", "block"],
          mt: "30px",
          width: "65px",
          height: "65px",
          border: `2px solid ${color || "white"}`,
          borderRadius: "100%",
          position: "relative",
        }}
      >
        <div
          sx={{
            width: "10px",
            height: "10px",
            background: color || "white",
            borderRadius: "100%",
            position: "absolute",
            bottom: "8px",
            left: "50%",
            ml: "-5px",
            animationDuration: "1.8s",
            animationName: "pepe",
            animationIterationCount: "infinite",
            "@keyframes pepe": {
              "0%": { opacity: 1, bottom: "8px" },
              "80%, 100%": { opacity: 0, bottom: "45px" },
            },
          }}
        ></div>
      </div>
    </div>
  )
}
export default Scroller
