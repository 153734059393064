/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { getRelativeValue, renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import { useWindowScroll } from "react-use"
import { AnimatePresence, motion } from "framer-motion"

const CopyBlade = ({ data }) => {
  const ref = React.useRef(null)
  const { y: scrollY } = useWindowScroll()
  const offset =
    ref?.current &&
    ((scrollY -
      (ref.current.getBoundingClientRect().top +
        document.documentElement.scrollTop -
        window.innerHeight)) /
      ref.current.clientHeight -
      0.2) *
      3.75

  const renderLine = text => (
    <motion.div
      key={text}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      sx={{
        position: "absolute",
        bottom: 0,
        fontSize: ["40px", "40px", "40px", "40px", "50px", "64px"],
        lineHeight: "110%",
        maxWidth: "1048px",
        fontWeight: 500,
        mb: 0,
      }}
    >
      {renderLineBreak(text)}
    </motion.div>
  )
  return (
    <div ref={ref} sx={{ position: "relative", height: "500vh" }}>
      <div
        sx={{
          height: "100vh",
          width: "100%",
          position: "sticky",
          top: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#10182b",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            color: "#E3E3EB",
          }}
        >
          <div
            sx={{
              height: ["40vh", "30vh"],
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "center",
              position: "relative",
              width: ["100%", "100%", "80%"],
            }}
          >
            <AnimatePresence>
              {offset < 1 && offset >= 0 && renderLine(data.slide1)}
              {offset < 2 && offset > 1 && renderLine(data.slide2)}
              {offset > 2 && renderLine(data.slide3)}
            </AnimatePresence>
          </div>
          <div
            sx={{
              mt: "39px",
              width: "153px",
              height: "8px",
              border: "1px solid #D4576E",
              backgroundImage:
                "linear-gradient(90deg, #D4576E 50%,transparent 55%)",
              backgroundSize: "612px 8px",
              backgroundPositionX: `${(offset / 3) * 153 - 306}px`,
            }}
          ></div>
        </Container>
      </div>
    </div>
  )
}
export default CopyBlade
