/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import hikersFg from "../../images/blade-9-hikers.png"

const ReasonsBlade = ({ data }) => {
  return (
    <React.Fragment>
      <Slide
        sx={{
          minHeight: "120vh",
          py: "50px",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            zIndex: -1,
            backgroundImage: [
              "linear-gradient(180deg, #10182b 5%, transparent 50%), radial-gradient(circle at 60% 340%, #becff3 70%, #6176a5 80%, #21376a 90%, #111c37)",
              "linear-gradient(180deg, #10182b 5%, transparent 50%), radial-gradient(circle at 60% 280%, #becff3 70%, #6176a5 80%, #21376a 90%, #111c37)",
              "linear-gradient(180deg, #10182b 5%, transparent 50%), radial-gradient(circle at 60% 280%, #becff3 70%, #6176a5 80%, #21376a 90%, #111c37)",
              "linear-gradient(180deg, #10182b 5%, transparent 50%), radial-gradient(circle at 60% 280%, #becff3 70%, #6176a5 80%, #21376a 90%, #111c37)",
              "radial-gradient(circle at 60% 280%, #becff3 70%, #6176a5 80%, #21376a 90%, #111c37)",
            ],
          }}
        >
          <div sx={{ width: "100%", height: "100%", position: "absolute" }}>
            <div sx={{ position: "absolute", bottom: "-70px" }}>
              <img
                src={hikersFg}
                sx={{
                  width: ["200%", "200%", "100%"],
                  height: ["300px", "300px", "400px", "400px", "auto"],
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </div>
        <Container
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "1075px",
            py: "30px",
            maxHeight: ["unset", "unset", "unset", "unset", "500px"],
            pb: ["150px", "150px", "200px", "200px", "30px"],
          }}
        >
          <h2
            sx={{
              fontSize: ["30px", "24px", "24px", "24px", "34px"],
              fontWeight: 500,
              color: "#E3E3EB",
            }}
          >
            {data.title}
          </h2>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: ["1fr", "1fr 1fr"],
              columnGap: "74px",
              flexGrow: 1,
              alignItems: "center",
              maxWidth: "1100px",
              justifyContent: "space-around",
            }}
          >
            {data.keys.map((key, index) => (
              <div
                key={index}
                sx={{
                  color: "#E3E3EB",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: ["wrap", "nowrap"],
                  py: ["10px", "10px", "20px", "20px", "20px", "30px"],
                }}
              >
                <div
                  sx={{
                    fontSize: ["64px", "44px", "44px", "64px", "84px"],
                    mr: ["-5px", "-5px", "-5px", "-16px"],
                  }}
                >
                  {index + 1}
                </div>
                <div
                  sx={{
                    fontSize: ["48px", "40px", "40px", "60px", "80px"],
                    position: "relative",
                    top: ["-15px", "2px"],
                    left: ["5px", 0],
                    lineHeight: "100%",
                    mr: ["18px"],
                    fontWeight: 200,
                  }}
                >
                  /
                </div>
                <div
                  sx={{
                    fontSize: ["18px", "16px", "16px", "16px", "20px"],
                    lineHeight: "110%",
                    position: "relative",
                    top: ["0px", "8px"],
                    mb: ["20px", 0],
                  }}
                >
                  {key}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Slide>
    </React.Fragment>
  )
}
export default ReasonsBlade
