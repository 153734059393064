/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import CarouselController from "./common/context"
import CarouselPagination from "./common/carousel-pagination"
import CarouselNavigation from "./common/carousel-navigation"
import CarouselSlider from "./common/carousel-slider"

const Carousel = ({ children, type }) => {
  return (
    <CarouselController totalSlides={React.Children.count(children)}>
      <div sx={{ position: "relative" }}>
        <CarouselSlider>{children}</CarouselSlider>
        <div
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
            mb: "50px",
          }}
        >
          <CarouselPagination
            type={type}
            triggerStyle={{
              mr: 1,
              mb: 0,
              ":last-child": { mr: 0 },
            }}
          />
        </div>
        {type !== "heights" && (
          <div
            sx={{
              display: "flex",
            }}
          >
            <CarouselNavigation previousControllerStyle={{ mr: 2 }} />
          </div>
        )}
      </div>
    </CarouselController>
  )
}

Carousel.propTypes = { children: PropTypes.node.isRequired }

export default Carousel
