/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Scroller from "../scroller"
import Slide from "../slide"
import mountainImage from "../../images/blade-1-mountain.png"
import AnimateChildren from "../animate-children"

const HomeBlade = ({ data }) => {
  return (
    <div sx={{ position: "relative" }}>
      <div
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          sx={{
            backgroundImage: [
              `radial-gradient(circle at 100% -800%, #f3f4f5 90%, #edd9e0 96%, #80a6e0 98%)`,
              `radial-gradient(circle at 100% -800%, #f3f4f5 90%, #edd9e0 96%, #80a6e0 98%)`,
              `radial-gradient(circle at 100% -800%, #f3f4f5 90%, #edd9e0 94%, #80a6e0 97%)`,
              `radial-gradient(circle at 100% -800%, #f3f4f5 90%, #edd9e0 94%, #80a6e0 97%)`,
              `radial-gradient(circle at 100% -800%, #f3f4f5 90%, #edd9e0 93%, #80a6e0 95%)`,
            ],
            position: "absolute",
            top: 0,
            width: "100%",
            height: "80%",
            zIndex: -1,
          }}
        ></div>
        <div
          sx={{
            height: "75vh",
            minHeight: ["750px", "600px", "650px", "650px", "400px"],
          }}
        ></div>
        <div
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <img
            sx={{
              height: ["60vh", "60vh", "70vh", "80vh", "100vh"],
              width: "100%",
              objectFit: "cover",
              mt: ["-70px", 0],
            }}
            src={mountainImage}
          />
        </div>
        <div sx={{ background: "#171e2f", width: "100%", flexGrow: 1 }}></div>
      </div>
      <Slide
        sx={{
          height: ["unset", "unset", "unset", "unset", "100vh"],
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "570px",
          pt: ["130px", "130px", "130px", "130px", 0],
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "column", "column", "row"],
            justifyContent: "space-between",
            alignItems: [
              "flex-start",
              "flex-start",
              "flex-start",
              "flex-start",
              "center",
            ],
            height: "100%",
          }}
        >
          <div sx={{ width: ["100%", "100%", "80%", "60%", "60%"] }}>
            <h1
              sx={{
                fontSize: ["40px", "50px", "50px", "50px", "60px", "74px"],
                lineHeight: ["40px", "54px", "54px", "54px", "64px", "78px"],
                mb: 0,
              }}
            >
              {renderLineBreak(data.title)}
            </h1>
            <div
              sx={{
                fontFamily: "source code pro",
                fontWeight: 600,
                textTransform: "uppercase",
                fontSize: "16px",
                mt: ["9px", "9px", "9px", "9px", "19px", "29px"],
              }}
            >
              {renderLineBreak(data.subtitle)}
            </div>
            <div
              sx={{
                fontSize: "20px",
                lineHeight: "28px",
                mt: ["20px", "20px", "20px", "20px", "50px", "60px"],
                pb: ["200px", "200px", "250px", "250px", 0],
              }}
            >
              {renderLineBreak(data.body)}
            </div>
          </div>
          <AnimateChildren
            visibleStyle={{ opacity: 1, top: 0 }}
            invisibleStyle={{ opacity: 0, top: "100px" }}
            transition={{ duration: 0.9 }}
            sx={{
              position: "relative",
              mb: "20px",
              width: ["30%", "30%", "30%", "30%", "auto"],
              px: ["10px", "20px", "30px", "40px", 0],
            }}
            delay={0.4}
            initialDelay={1}
            containerStyle={{
              mt: ["-170px", "-170px", "-200px", "-200px", 0, 0],
              pt: [0, 0, 0, 0, "150px", "250px"],
              pb: "150px",
              height: "100%",
              display: "flex",
              justifyContent: [
                "center",
                "center",
                "center",
                "center",
                "space-between",
              ],
              alignItems: ["center", "center", "center", "center", "flex-end"],
              flexDirection: ["row", "row", "row", "row", "column"],
              maxHeight: "900px",
              flexWrap: ["wrap", "wrap", "wrap", "wrap", "nowrap"],
            }}
          >
            {data.logos.map((logo, index) => (
              <img
                key={index}
                src={logo.img}
                sx={{
                  width: "auto",
                  height: "auto",
                  mr: [0, 0, 0, 0, logo.right],
                }}
              />
            ))}
          </AnimateChildren>
        </Container>
        <AnimateChildren
          visibleStyle={{ opacity: 1 }}
          invisibleStyle={{ opacity: 0 }}
          transition={{ duration: 0.9 }}
          sx={{
            position: "relative",
          }}
          delay={0}
          initialDelay={3.7}
          containerStyle={{}}
        >
          <Scroller sx={{ bottom: "10px" }}> {data.scroll}</Scroller>
        </AnimateChildren>
      </Slide>
      <Slide
        sx={{
          height: "100vh",
          width: "100%",
          position: "relative",
        }}
      >
        <Container
          sx={{
            height: "100%",
          }}
        >
          <AnimateChildren
            visibleStyle={{ opacity: 1, top: 0 }}
            invisibleStyle={{ opacity: 0, top: "100px" }}
            transition={{ duration: 1 }}
            sx={{ position: "relative" }}
            initialDelay={0.5}
            delay={1}
            containerStyle={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: ["center", "center", "space-evenly"],
            }}
          >
            <div
              sx={{
                width: ["100%", "80%", "70%", "60%", "65%"],
                fontSize: ["40px", "40px", "44px", "54px", "64px"],
                lineHeight: "110%",
                color: "#E3E3EB",
                fontWeight: 500,
                my: ["20px", "20px", 0],
              }}
            >
              {renderLineBreak(data.warmerP1)}
            </div>
            <div sx={{ width: "100%" }}>
              <div
                sx={{
                  width: ["100%", "100%", "50%", "50%", "50%"],
                  maxWidth: "460px",
                  fontSize: ["20px", "20px", "20px", "20px", "26px"],
                  lineHeight: "110%",
                  color: "#E3E3EB",
                  float: "right",
                  my: ["20px", "20px", 0],
                }}
              >
                {renderLineBreak(data.warmerP2)}
              </div>
            </div>
          </AnimateChildren>
        </Container>
      </Slide>
    </div>
  )
}
export default HomeBlade
