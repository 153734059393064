/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import waterfallFg from "../../images/blade-waterfall.png"

const TopHealthcareBlade = ({ data }) => {
  return (
    <div sx={{ position: "relative" }}>
      <div
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          overflow: "hidden",
        }}
      >
        <div
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              position: "absolute",
              top: "30vh",
            }}
          >
            <img
              src={waterfallFg}
              sx={{
                height: ["200vh", "150vh", "100vh", "100vh", "100vh"],
                minWidth: "101vw",
                width: "100%",
                objectFit: "cover",
                objectPosition: "top",
                opacity: 0.7,
                position: "relative",
                top: "7px",
                maskImage:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 85%)",
              }}
            />
          </div>
        </div>
      </div>
      <Slide
        sx={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            backgroundImage: [
              "linear-gradient(90deg, #e4eaec,#f6f0e6,#e9e4ea)",
            ],
            maskImage:
              "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        ></div>
        <Container
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            py: "30px",
            color: "#171E2F",
          }}
        >
          <h2
            sx={{
              fontSize: ["44px", "54px", "44px", "54px", "64px"],
              fontWeight: 500,
            }}
          >
            {data.title}
          </h2>
          <div
            sx={{
              fontSize: ["54px", "64px", "54px", "64px", "74px"],
              lineHeight: "110%",
              fontWeight: 500,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              display: ["none", "none", "flex"],
            }}
          >
            <div>{renderLineBreak(data.slashA)}</div>
            <div
              sx={{
                mx: [0, 0, "60px", "80px", "100px"],
                my: "40px",
              }}
            >
              <div
                sx={{
                  height: "271px",
                  width: "5px",
                  transform: "rotate(18deg)",
                  backgroundImage: "linear-gradient(180deg, #a97be4, #4d6297)",
                }}
              ></div>
            </div>
            <div>{renderLineBreak(data.slashB)}</div>
          </div>
          <div
            sx={{
              fontSize: ["44px", "54px"],
              my: "100px",
              width: "100%",
              lineHeight: "110%",
              fontWeight: 500,
              flexDirection: "column",
              display: ["flex", "flex", "none"],
            }}
          >
            <div>{renderLineBreak(data.slashA)}</div>
            <div
              sx={{
                width: "100%",
                height: "5px",
                my: "50px",
                backgroundImage: "linear-gradient(180deg, #a97be4, #4d6297)",
              }}
            ></div>
            <div>{renderLineBreak(data.slashB)}</div>
          </div>
          <div
            sx={{
              width: ["100%", "100%", "50%"],
              ml: [0, 0, "50%"],
              fontSize: "26px",
              lineHeight: "120%",
            }}
          >
            {data.paragraph}
          </div>
        </Container>
      </Slide>
      <div
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Slide
          sx={{
            minHeight: "100vh",
          }}
        >
          <div
            sx={{
              position: "absolute",
              width: "100%",
              backgroundImage:
                "linear-gradient(-45deg, #e09bbc, #d0dcf6 20%, transparent 40%), linear-gradient(45deg, #eae0f3, #f8f2f4, #f4d5e1 60%)",
              maskImage:
                "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
              height: "100%",
              zIndex: -1,
            }}
          ></div>
          <Container
            sx={{
              py: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h2
              sx={{
                fontSize: ["44px", "54px", "44px", "54px", "64px"],
                fontWeight: 500,
              }}
            >
              {data.whatWeDoTitle}
            </h2>
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                maxWidth: "970px",
              }}
            >
              {data.cards.map((card, index) => (
                <div
                  key={index}
                  sx={{
                    mt: ["20px", "30px", "77px"],
                    mx: [0, "10px"],
                    width: "100%",
                    maxWidth: ["302px", "262px", "262px", "302px"],
                  }}
                >
                  <img sx={{ mb: "8px" }} src={card.icon} />
                  <div
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      pb: "17px",
                      borderBottom: "2px solid #4C6195",
                    }}
                  >
                    <div
                      sx={{
                        maxWidth: "214px",
                      }}
                    >
                      {card.title}
                    </div>
                  </div>
                  <ul sx={{ mt: "18px" }}>
                    {card.items.map((item, index) => (
                      <li key={index} sx={{ mb: "10px" }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </Container>
        </Slide>
        <Slide
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              sx={{
                fontSize: ["44px", "54px", "44px", "54px", "64px"],
                fontWeight: 500,
                my: ["30px", "30px", "30px", "184px"],
                maxWidth: "970px",
              }}
            >
              {data.finalParagraph}
            </h2>
          </Container>
        </Slide>
      </div>
    </div>
  )
}
export default TopHealthcareBlade
