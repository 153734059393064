/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { getRelativeValue, renderLineBreak } from "../../helpers"
import Container from "../container"
import CTA from "../cta"
import Scroller from "../scroller"
import Slide from "../slide"

const ChallengesBlade = ({ data }) => {
  const [enabledItems, setEnabledItems] = React.useState([])
  const toggleItem = index => {
    if (enabledItems.includes(index)) {
      setEnabledItems(enabledItems =>
        enabledItems.filter(itemIndex => itemIndex != index)
      )
    } else {
      setEnabledItems(enabledItems => [...enabledItems, index])
    }
  }
  const allSelectedKeys = data.challenges
    .filter((item, index) => enabledItems.includes(index))
    .map(item => item.keys)
    .reduce((arr, keys) => {
      return [...arr, ...keys]
    }, [])
  let selectedKeySet = new Set()
  allSelectedKeys.forEach(key => selectedKeySet.add(key))
  const selectedKeys = [...selectedKeySet]
  const keyString =
    selectedKeys.length &&
    (selectedKeys.length === 1
      ? selectedKeys[0]
      : selectedKeys.slice(0, selectedKeys.length - 1).join(", ") +
        " and " +
        selectedKeys[selectedKeys.length - 1])
  const [continues, setContinues] = React.useState(false)
  const secondRef = React.useRef(null)
  const onContinue = () => {
    if (enabledItems.length) {
      setContinues(true)
      setTimeout(
        () =>
          window.scrollTo({
            left: 0,
            top: secondRef.current.parentElement.offsetTop,
            behavior: "smooth",
          }),
        100
      )
    }
  }
  return (
    <React.Fragment>
      <Slide
        sx={{
          pt: "143px",
          pb: "30px",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          background: "#e4e4ec",
          backgroundImage: [
            "radial-gradient(circle at 50% 130%, #a589f4 20%, #e4e4ec 30%, #e2e5ef)",
            "none",
          ],
          scrollSnapAlign: "none",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            color: "#E3E3EB",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <h2 sx={{ color: "#171E2F", mb: "27px" }}>{data.title}</h2>
              <div
                sx={{
                  color: "#171E2F",
                  fontFamily: "source code pro",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {data.subtitle}
              </div>
            </div>
          </div>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: [
                "1fr",
                "1fr 1fr",
                "repeat(3, 1fr)",
                "repeat(3, 1fr)",
                "repeat(5, 1fr)",
              ],
              columnGap: "22px",
              rowGap: "30px",
              mt: "100px",
            }}
          >
            {data.challenges.map((challenge, index) => {
              let active = enabledItems.includes(index)
              return (
                <div key={index} sx={{ position: "relative" }}>
                  <div
                    onClick={() => toggleItem(index)}
                    className={active ? "active" : ""}
                    sx={{
                      cursor: "pointer",
                      height: "200px",
                      border: "3px solid #4C6195",
                      color: "#4C6195",
                      fontSize: "22px",
                      backgroundColor: "#e4e4ec",
                      p: "15px",
                      position: "relative",
                      transition:
                        "border-color 0.2s, background-color 0.2s, opacity 0.2s",
                      zIndex: 9,
                      ":hover, &.active": {
                        backgroundColor: "#eeeeee",
                        borderColor: "#eeeeee",
                        opacity: 0.6,
                        "&+div>div": {
                          opacity: 1,
                        },
                      },
                      "&.active": {
                        fontWeight: 600,
                      },
                    }}
                  >
                    {renderLineBreak(challenge.text)}
                  </div>
                  <div
                    sx={{
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      sx={{
                        width: 0,
                        height: 0,
                        position: "absolute",
                        bottom: "30px",
                        left: "100px",
                        boxShadow: "0px 0px 60px 30px #9eb7fc",
                        opacity: 0,
                        transition: "0.3s",
                        ...(enabledItems.includes(index) && {
                          opacity: 1,
                        }),
                      }}
                    ></div>
                    <div
                      sx={{
                        width: 0,
                        height: 0,
                        position: "absolute",
                        top: "40px",
                        left: "35px",
                        boxShadow: "0px 0px 60px 30px #9eb7fc",
                        opacity: 0,
                        transition: "0.3s",
                        ...(enabledItems.includes(index) && {
                          opacity: 1,
                        }),
                      }}
                    ></div>
                    <div
                      sx={{
                        width: 0,
                        height: 0,
                        position: "absolute",
                        bottom: "50px",
                        right: "40px",
                        boxShadow: "0px 0px 60px 30px #b17aad",
                        opacity: 0,
                        transition: "0.3s",
                        ...(enabledItems.includes(index) && {
                          opacity: 1,
                        }),
                      }}
                    ></div>
                  </div>
                </div>
              )
            })}
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              pt: "80px",
            }}
          >
            <CTA
              sx={{
                maxWidth: "295px",
                ...(!(enabledItems.length > 0 && continues) && { mb: "120px" }),
              }}
              onClick={onContinue}
            >
              Continue
            </CTA>
          </div>
        </Container>
      </Slide>
      <Slide
        sx={{
          background: "#e4e4ec",
          backgroundImage:
            "radial-gradient(circle at 0% 110%, #afeff8, transparent 30%), radial-gradient(circle at 100% 110%, #eba7ef, transparent 30%), linear-gradient(0deg, #aa94f5, transparent 40%)",

          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          color: "#171E2F",
          scrollSnapAlign: "center",
          ...(!(enabledItems.length > 0 && continues) && { height: 0 }),
        }}
      >
        <div ref={secondRef}>
          {enabledItems.length > 0 && continues && (
            <React.Fragment>
              <Container>
                <h1 sx={{ mb: 0, fontSize: ["44px", "44px", "64px"] }}>
                  {data.message.replace("{keys}", keyString.toLowerCase())}
                </h1>
              </Container>
              <Scroller color={"#4b6196"} sx={{ mb: "30px" }}>
                {data.scroll}
              </Scroller>
            </React.Fragment>
          )}
        </div>
      </Slide>
    </React.Fragment>
  )
}
export default ChallengesBlade
