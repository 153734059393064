/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"

const ProblemsBlade = ({ data }) => {
  const gradients = [
    "radial-gradient(circle at 30% 0%, #b6d5fc 20%, #c4e4ea)",
    "radial-gradient(circle at 80% 70%, #97b4fe 40%, #c4e4ea)",
    "radial-gradient(circle at 120% 100%, #e9e6b4 5%, #97b4fe 50%)",
    "radial-gradient(circle at 120% 100%, #c7e6e4 50%, #d5daf6)",
    "radial-gradient(circle at 0% 50%, #d6ddfa 40%, #97b4fe 60%)",
    "radial-gradient(circle at 160% 50%, #d9dcc1 40%, #97b4fe 80%)",
  ]
  return (
    <React.Fragment>
      <Slide
        sx={{
          minHeight: "100vh",
          backgroundColor: "#171e2f",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: "80px",
            height: "100%",
            width: "100%",
            position: "relative",
            color: "#E3E3EB",
          }}
        >
          <h1
            sx={{
              fontSize: ["40px", "40px", "40px", "40px", "50px", "64px"],
              lineHeight: "110%",
              width: ["100%", "100%", "100%", "80%"],
              maxWidth: "1048px",
              fontWeight: 500,
              mb: ["30px", "40px", "50px", "60px", "70px", "80px"],
            }}
          >
            {data.title}
          </h1>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "1000px",
              justifyContent: "center",
            }}
          >
            {data.cards.map((card, index) => (
              <div
                key={index}
                sx={{
                  width: ["100%", "300px"],
                  height: "200px",
                  mx: [0, "15px"],
                  my: "15px",
                  background: gradients[index] || "white",
                  color: "#171e2f",
                  fontSize: "18px",
                  lineHeight: "110%",
                  p: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  strong: { fontWeight: "500", fontStyle: "italic" },
                }}
              >
                <div sx={{ flexGrow: 1 }}>{card.content}</div>
                <div>
                  <span
                    sx={{
                      fontSize: "86px",
                      position: "relative",
                      bottom: "3px",
                    }}
                  >
                    {index + 1}
                  </span>
                  <img sx={{ ml: index == 1 ? "8px" : 0 }} src={card.image} />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Slide>
    </React.Fragment>
  )
}
export default ProblemsBlade
