/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { getRelativeValue, renderLineBreak } from "../../helpers"
import Container from "../container"
import Slide from "../slide"
import processBg from "../../images/process.svg"

const ProcessBlade = ({ data }) => {
  const rayTextXPositions = [
    ["100px", "100px", "100px", "100px", "126px"],
    ["240px", "240px", "240px", "240px", "325px"],
    ["365px", "365px", "365px", "365px", "502px"],
    ["510px", "510px", "510px", "510px", "670px"],
    ["630px", "630px", "630px", "630px", "870px"],
    ["765px", "765px", "765px", "765px", "1050px"],
  ]
  const rayIconXPositions = [
    ["85px", "85px", "85px", "85px", "116px"],
    ["230px", "230px", "230px", "230px", "308px"],
    ["376px", "376px", "376px", "376px", "515px"],
    ["520px", "520px", "520px", "520px", "705px"],
    ["665px", "665px", "665px", "665px", "905px"],
    ["820px", "820px", "820px", "820px", "1110px"],
  ]
  return (
    <React.Fragment>
      <Slide
        sx={{
          minHeight: "100vh",
          width: "100%",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#10182b",
        }}
      >
        <Container
          sx={{
            pt: ["60px", "60px", "60px", "100px"],
            pb: [0, 0, 0, "100px"],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            color: "#E3E3EB",
          }}
        >
          <div
            sx={{
              fontSize: "34px",
              fontWeight: 500,
              maxWidth: "768px",
              lineHeight: "110%",
              mb: "100px",
            }}
          >
            {data.title}
          </div>
          <div
            sx={{
              position: "relative",
              display: ["none", "none", "none", "block"],
              color: "#10182b",
              width: ["970px", "970px", "970px", "970px", "1296px"],
              height: ["666px", "666px", "666px", "666px", "897px"],
            }}
          >
            <img
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={processBg}
            />
            {data.rays.map((ray, index) => (
              <React.Fragment key={index}>
                <img
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: rayIconXPositions[index],
                  }}
                  src={ray.icon}
                />
                <div
                  sx={{
                    position: "absolute",
                    top: "100px",
                    left: rayTextXPositions[index],
                    fontSize: "20px",
                    fontWeight: 500,
                    width: ["140px", "140px", "140px", "140px", "auto"],
                  }}
                >
                  {renderLineBreak(ray.text)}
                </div>
              </React.Fragment>
            ))}
            {data.arrows.map((arrow, index) => (
              <div
                key={index}
                sx={{
                  position: "absolute",
                  left: [0, 0, 0, "320px", "403px"],
                  top: [
                    [111, 188],
                    [111, 188],
                    [111, 188],
                    [111, 188],
                    [150, 259],
                  ].map(values => index * values[0] + values[1]),
                  width: [0, 0, 0, "550px", "738px"],
                }}
              >
                <div
                  sx={{ fontSize: [0, 0, 0, "22px", "28px"], fontWeight: 500 }}
                >
                  {arrow.title}
                </div>
                <div sx={{ lineHeight: "110%", mt: [0, 0, 0, 0, "9px"] }}>
                  {renderLineBreak(arrow.text)}
                </div>
              </div>
            ))}
          </div>
          <div sx={{ display: ["block", "block", "block", "none"] }}>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                p: "30px",
                position: "relative",
              }}
            >
              {data.rays.map(ray => (
                <div
                  sx={{
                    background: "#e0e3f2",
                    width: ["120px", "170px"],
                    m: "10px",
                    height: ["120px", "170px"],
                    borderRadius: "100%",
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#10182b",
                  }}
                >
                  <img src={ray.icon} />
                  <div
                    sx={{
                      fontSize: ["14px", "18px"],
                      maxWidth: ["85px", "unset"],
                      textAlign: "center",
                      lineHeight: "110%",
                      mt: [0, "10px"],
                      fontWeight: ["400", "500"],
                    }}
                  >
                    {ray.text}
                  </div>
                </div>
              ))}
              <div
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  sx={{
                    width: ["287px", "382px"],
                    height: ["150px", "200px"],
                    border: "5px solid white",
                    borderLeft: 0,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Container>
        <Container
          sx={{
            display: ["block", "block", "block", "none"],
            background: "#9382BC",
            pt: ["280px", "340px"],
            mt: ["-242px", "-318px"],
            px: "30px",
          }}
        >
          {data.arrows.map(arrow => (
            <div sx={{ color: "#171E2F", mb: "60px" }}>
              <div sx={{ fontSize: "28px", fontWeight: 500 }}>
                {arrow.title}
              </div>
              <div sx={{ mt: "9px" }}>{renderLineBreak(arrow.text)}</div>
            </div>
          ))}
        </Container>
      </Slide>
    </React.Fragment>
  )
}
export default ProcessBlade
