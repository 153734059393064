/** @jsx jsx */
import { jsx } from "theme-ui"
import { renderLineBreak } from "../../helpers"
import Container from "../container"
import Scroller from "../scroller"
import Slide from "../slide"

const TruthBlade = ({ data }) => {
  return (
    <Slide
      sx={{
        minHeight: "100vh",
        width: "100%",
        position: "relative",
        background: "#171e2f",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        sx={{
          height: "100%",
          py: "20px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: ["space-between"],
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            flexGrow: 1,
          }}
        >
          <div
            sx={{
              width: ["100%", "80%", "70%", "60%", "65%"],
              fontSize: ["40px", "40px", "44px", "54px", "64px"],
              lineHeight: "110%",
              color: "#E3E3EB",
              fontWeight: 500,
              my: ["20px", "20px", 0],
            }}
          >
            {renderLineBreak(data.warmerP1)}
          </div>
          <div sx={{ width: "100%" }}>
            <div
              sx={{
                width: ["100%", "100%", "50%", "50%", "50%"],
                maxWidth: "460px",
                fontSize: ["20px", "20px", "20px", "20px", "26px"],
                lineHeight: "110%",
                color: "#E3E3EB",
                float: "right",
                my: ["20px", "20px", 0],
              }}
            >
              {renderLineBreak(data.warmerP2)}
            </div>
          </div>
        </div>
        <Scroller sx={{ position: "relative" }}>{data.scroller}</Scroller>
      </Container>
    </Slide>
  )
}
export default TruthBlade
