/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import HomeBlade from "../components/blades/home-blade"
import QuoteBlade from "../components/blades/quote-blade"
import CopyBlade from "../components/blades/copy-blade"
import ReasonsBlade from "../components/blades/reasons-blade"
import ChallengesBlade from "../components/blades/challenges-blade"
import topLogo1 from "../images/logos-top/BH-logo.png"
import topLogo2 from "../images/logos-top/Aetna-logo.png"
import topLogo3 from "../images/logos-top/KP-logo.png"
import topLogo4 from "../images/logos-top/Mckesson-logo.png"
import topLogo5 from "../images/logos-top/GR-logo.png"
import ProblemsBlade from "../components/blades/problems-blade"
import problemIcon1 from "../images/icons-problems-section/icons-problems_ROI.png"
import problemIcon2 from "../images/icons-problems-section/icons-problems_priority.png"
import problemIcon3 from "../images/icons-problems-section/icons-problems_process.png"
import problemIcon4 from "../images/icons-problems-section/icons-problems_talent.png"
import problemIcon5 from "../images/icons-problems-section/icons-problems_support.png"
import problemIcon6 from "../images/icons-problems-section/icons-problems_maintenance.png"
import TruthBlade from "../components/blades/truth-blade"
import transformationLogo1 from "../images/logos-statements/adobe-logo.png"
import transformationLogo2 from "../images/logos-statements/M&co-logo.png"
import transformationLogo3 from "../images/logos-statements/accenture-logo.png"
import transformationLogo4 from "../images/logos-statements/companies-logos.png"
import transformationLogo5 from "../images/logos-statements/HITN-logo.png"
import transformationLogo6 from "../images/logos-statements/blackbook-logo.png"
import TransformationBlade from "../components/blades/transformation-blade"
import SecretBlade from "../components/blades/secret-blade"
import OutcomeBlade from "../components/blades/outcome-blade"
import TopHealthcareBlade from "../components/blades/top-healthcare-blade"
import whatWeDoIcon1 from "../images/icons-wwd/1.png"
import whatWeDoIcon2 from "../images/icons-wwd/2.png"
import whatWeDoIcon3 from "../images/icons-wwd/3.png"
import whatWeDoIcon4 from "../images/icons-wwd/4.png"
import whatWeDoIcon5 from "../images/icons-wwd/5.png"
import ProcessExplainedBlade from "../components/blades/process-explained-blade"
import handIcon1 from "../images/icons-hands/1.png"
import handIcon2 from "../images/icons-hands/2.png"
import handIcon3 from "../images/icons-hands/3.png"
import handIcon4 from "../images/icons-hands/4.png"
import CommitmentBlade from "../components/blades/commitment-blade"
import FinalBlade from "../components/blades/final-blade"
import quotePortrait1 from "../images/quote-portrait.jpg"
import thankYouIcon from "../images/icon-mountain_mountain.png"
import ProcessBlade from "../components/blades/process-blade"
import processIcon1 from "../images/icons-chart/1.png"
import processIcon2 from "../images/icons-chart/2.png"
import processIcon3 from "../images/icons-chart/3.png"
import processIcon4 from "../images/icons-chart/4.png"
import processIcon5 from "../images/icons-chart/5.png"
import processIcon6 from "../images/icons-chart/6.png"
import HeightsBlade from "../components/blades/heights-blade"
import ContactForm from "../components/contact-form"
import Modal from "../components/modal"
import Header from "../components/header"
import Footer from "../components/footer"

const IndexPage = ({ ...restProps }) => {
  const { site } = useStaticQuery(graphql`
    {
      site: contentfulMinisite(slug: { eq: "health" }) {
        metaDescription {
          metaDescription
        }
        siteTitle
        jsonData {
          jsonData
        }
      }
    }
  `)
  //const data = site?.jsonData?.jsonData && JSON.parse(site.jsonData.jsonData)
  const data = {
    home: {
      title: "Stay ahead in the shifting digital world of Healthcare.",
      subtitle: "Maximize your ROI  |  Optimize your CX",
      body:
        "Since 2003, Propane has helped top healthcare brands and cutting edge startups engage, grow, and deliver better outcomes.",
      scroll: "scroll to see how we help healthcare brands",
      warmerP1:
        "If you’re challenged by maximizing ROI in your healthcare transformation, you’re not alone.",
      warmerP2:
        "Across the healthcare industry, we consistently hear frustrations across our clients wether they are healthcare networks, device manufacturers, insurance providers, or care providers.",
      logos: [
        { img: topLogo1, right: "177px" },
        { img: topLogo2, right: "0px" },
        { img: topLogo3, right: "205px" },
        { img: topLogo4, right: "0px" },
        { img: topLogo5, right: "45px" },
      ],
    },
    quote: {
      largeCopy: {
        firstLine: "Executive leadership\n of top healthcare\n organizations",
        secondLine: "are all saying the same thing...",
      },
      quotes: [
        {
          title:
            "It’s hard to get executive buy-in or funding for initiatives.",
          subtitle: "Chief Medical Officers",
        },
        {
          title:
            "Even if we do build something,we don’t always know how effective it is.",
          subtitle: "Chief operations Officers",
        },
        {
          title: "Our time to market is too slow.",
          subtitle: "Chief strategy Officers",
        } /*
        {
          title:
            "Even if we can figure out solutions, we can’t get the resources to build them correctly.",
          subtitle: "Chief Technology Officers",
        },
        {
          title: "We can’t differentiate ourselves effectively.",
          subtitle: "Chief marketing Officers",
        },
        {
          title: "Its constant jockeying for position with our competitors.",
          subtitle: "Chief executive Officers",
        },
        {
          title: "Our Competitors are more digitally mature.",
          subtitle: "Chief Information Officers",
        },
        {
          title:
            "Tech consumer experiences are getting better, but we aren’t keeping up. That makes patients harder to satisfy.",
          subtitle: "Chief Innovation Officers",
        },
        {
          title:
            "Despite the best upfront business case, unpredictable issues constantly eat into our ROI.",
          subtitle: "Chief Financial Officers",
        },*/,
      ],
      deviceTitle:
        "The success of your healthcare organization is directly tied to sound digital strategies.",
      deviceScroller: "Overcome these challenges",
    },
    copy: {
      slide1:
        "So, what’s preventing healthcare organizations from maximizing their digital experience ROI?",
      slide2: "In healthcare, it’s rarely just one problem.",
      slide3:
        "Across thousands of hours of healthcare executive workshops, stakeholder interviews, and delivered projects, Propane has identified…",
    },
    reasons: {
      title:
        "6 key reasons organizations are slow in overcoming these challenges:",
      keys: [
        "It’s difficult to make business cases and measure ROI when patient outcomes and satisfaction are included as KPIs.",
        "Departments (service lines, operations, IT, etc.) often work in silos, leading to a lack of both shared priority and a holistic process.",
        "Finding product-market fit requires an iterative process, which is different from established approaches.",
        "Hiring for the right design process is expensive, extremely competitive, and requires specific cultural foundations.",
        "In-house digital teams lack the resources & power to innovate holistic digital solutions.",
        "Advocacy for the patient experience is often lacking ownership, leading to it being overlooked.",
      ],
    },
    problems: {
      title:
        "All the healthcare organizations we work with share the same types of problems when it comes to their digital experiences:",
      cards: [
        {
          content: (
            <span>
              Lack of business case for <strong>Attribution & ROI</strong>
            </span>
          ),
          image: problemIcon1,
        },
        {
          content: (
            <span>
              Little <strong>priority</strong>, strategy and singular digital
              roadmaps
            </span>
          ),
          image: problemIcon2,
        },
        {
          content: (
            <span>
              No digital <strong>process</strong> to execute, learn, and iterate{" "}
            </span>
          ),
          image: problemIcon3,
        },
        {
          content: (
            <span>
              Missing <strong>appropriate talent</strong> and resources across
              all digital practices
            </span>
          ),
          image: problemIcon4,
        },
        {
          content: (
            <span>
              A risk averse, <strong>Unsopportive culture</strong> when it comes
              to digital innovation
            </span>
          ),
          image: problemIcon5,
        },
        {
          content: (
            <span>
              Teams and resources are drowning in{" "}
              <strong>Legacy system maintenance</strong>, instead of innovating{" "}
            </span>
          ),
          image: problemIcon6,
        },
      ],
    },
    truth: {
      warmerP1: "The real truth:\nOne size doesn't fit all.",
      warmerP2:
        "Healthcare organizations need solutions and partners that address their specific challenges in their business and market directly and holistically.",
      scroller: "Explore digital transformation for healthcare",
    },
    transformation: {
      title:
        "Where is healthcare in its digital transformation journey and what's at stake?",
      cards: [
        {
          highlight: "50%",
          title: "behind other industries",
          content:
            "In a recent survey, only 7% of healthcare and pharmaceutical companies said they had gone digital, compared to 15% of companies in other industries.",
          image: transformationLogo1,
        },
        {
          highlight: "83",
          title: "billion+ of healthcare digital transformation underway",
          isQuote: true,
          content:
            "From 2014 to 2018, there have been more than 580 healthcare technology deals in the United States, each more than $10 million, for a total of more than $83 billion in value.",
          image: transformationLogo2,
        },
        {
          title: (
            <span>
              Healthcare touchpoints are <strong>Rapidly</strong> increasing
            </span>
          ),
          content:
            "The healthcare ecosystems of the future will likely be defined by the needs of different patient populations and their associated effective care journeys (including beyond care itself). The consumer-oriented nature of these ecosystems also will increase the number of healthcare touchpoints, with the goal of modifying patient behavior and improving outcomes.",
          image: transformationLogo3,
        },
        {
          title: "Tech companies building healthcare ecosystems",
          content:
            "The largest technology players, with proven track records of industry disruption, are building and curating their own healthcare ecosystems.",
          image: transformationLogo4,
        },
        {
          highlight: "89%",
          title: "of finance officers are investigating in digital",
          isQuote: true,
          content:
            "To stay competitive in the current healthcare provider marketplace, 70% of CFO respondents and 89% of all other senior finance officers in health systems plan to increase their investment in digital transformations by the end of 2020.",
          image: transformationLogo5,
        },
        {
          highlight: "85%",
          title: "also admit they don't know how to measure ROI",
          isQuote: true,
          content:
            "85% of senior healthcare financial leaders surveyed reveal that they don't have suitable financial metrics for IT ROI compared to 36% of FCOs in other industries.",
          image: transformationLogo6,
        },
      ],
      translationTitle: "Translation...",
      translationText: (
        <span>
          <strong>Digital</strong> is the key to staying competitive in the
          future. <strong>But</strong> efficiently maximizing ROI is still the
          missing piece for many healthcare brands. <strong>And</strong> failing
          to act means falling too far behind to catch up in the biggest
          opportunities in healthcare.
        </span>
      ),
    },
    challenges: {
      title: "Which challenges are you currently facing?",
      subtitle: "Click on your challenges below",
      challenges: [
        {
          text:
            "It is though to get leadership to fund great digital experiences.",
          keys: ["Culture", "Strategy"],
        },
        {
          text:
            "We are not aligned or incentivized to produce digital experiences.",
          keys: ["Culture", "Strategy"],
        },
        {
          text: "Our company doesn’t have a \n3–5 yr roadmap.",
          keys: ["Strategy", "Culture"],
        },
        {
          text:
            "A broad one size fits all approach to service line marketing does not work.",
          keys: ["Execution", "Strategy"],
        },
        {
          text:
            "Our available teams may not have the right skills or resources to build what consumers expect today.",
          keys: ["Digital Skill Set & Resources"],
        },
        {
          text:
            "It’s  difficult to align all the disparate teams, departments and contractors with one vision.",
          keys: ["Execution"],
        },
        {
          text:
            "We aren’t incentivized to let outside experts come in and build digital properties.",
          keys: ["Execution", "Culture"],
        },
        {
          text:
            "In-house resources are already busy on maintaining existing properties.",
          keys: ["Digital Skill Set & Resources"],
        },
        {
          text:
            "We need help making business cases to leadership for digital investments.",
          keys: ["Strategy"],
        },
        {
          text:
            "We don’t have baseline measurements across the entire customer journey to inform KPIs.",
          keys: ["Strategy"],
        },
      ],
      message: "It looks like you have challenges across {keys}.",
      scroll: "shh...want to hear our secret?",
    },
    secret: {
      text:
        "The secret to maximizing ROI, is aligning your culture, strategy, digital skillset and execution.",
    },
    heights: {
      title1: "If any one of these is not aligned...",
      title2: "it's hard to climb to new heights.",
      words: ["Culture", "Strategy", "Digital Skillset", "Execution"],
      subtitle: "Why?",
      cards: [
        {
          title: "Culture",
          text:
            "A digital mindset is key.\nIf every team isn’t aligned, digital initiatives will always be an uphill battle, no matter how good the strategy, resources, or execution.",
        },
        {
          title: "Strategy",
          text:
            "Digital is all about finding opportunities, validating them quickly through customer-centric insight, and prioritizing them to create your scalable roadmap for success.",
        },
        {
          title: "Digital Skillset",
          text:
            "Gaps in digital skill sets can bring down the best culture, compromise the best strategy, and overload the best execution. Which affects your customer experience.",
        },
        {
          title: "Execution",
          text:
            "If your organization can’t build, test, and learn, fast, while staying HIPAA and WCAG compliant, your culture will lose steam, your strategies will be overtaken by competitors, and digital talent will join winning organizations that can execute.",
        },
      ],
    },
    outcome: {
      title:
        "ROI is the outcome of aligning culture, strategy, digital skillset and execution.",
      subtitle:
        "Every Propane engagement focuses on aligning these paths, and results in ROI.",
      scroller: "Focus on aligning these paths to get true ROI.",
    },
    topHealthcare: {
      title: "How top healthcare brands get ROI.",
      slashA: "Management \nConsulting",
      slashB: "Digital \nExperience",
      paragraph:
        "Propane’s hybrid consultancy/agency approach, blends management consulting with digital experience execution to align your culture, strategies, skillsets and execution.",
      whatWeDoTitle: "What we do",
      cards: [
        {
          icon: whatWeDoIcon1,
          title: "Healthcare Brand Transformation",
          items: [
            "Brand Strategy Positioning",
            "Messaging",
            "Brand Identity Design",
            "Brand Customer Experience Management",
          ],
        },
        {
          icon: whatWeDoIcon2,
          title: "Healthcare Product Design & Innovation",
          items: [
            "Product & Service Design",
            "Product positioning & Strategy",
            "Market Research",
            "User Testing & Research",
            "Product-Market Fit Validation",
          ],
        },
        {
          icon: whatWeDoIcon3,
          title: "Health System Internal Tooling & Integrations",
          items: [
            "Intranet Design",
            "Content Strategy for Employee Engagement",
            "Training and Education Platforms",
            "Digital Tool Integration",
            "Revenue Operations",
          ],
        },
        {
          icon: whatWeDoIcon4,
          title: "Healthcare Marketing",
          items: [
            "Marketing Stack Integration",
            "Content Strategy and Content Marketing",
            "Websites and Ecosystems",
            "Attribution, Analytics and Measurement",
          ],
        },
        {
          icon: whatWeDoIcon5,
          title: "Patient Experience",
          items: [
            "Mobile Apps",
            "Service Line Consultation",
            "Patient Engagement/EHR Customization & Improvement",
            "Patient Education",
          ],
        },
      ],
      finalParagraph:
        "We maximize ROI by aligning every activity, deliverable, and aspect of our projects to serve your culture, strategy, skillset & execution at the same time.",
    },
    process: {
      title:
        "Our process is designed to maximize ROI through parallel alignment.",
      rays: [
        { icon: processIcon1, text: "Discovery" },
        { icon: processIcon2, text: "Strategy" },
        { icon: processIcon3, text: "Foundation" },
        { icon: processIcon4, text: "Detailed Design" },
        { icon: processIcon5, text: "Development" },
        { icon: processIcon6, text: "Measurement \n& Learning" },
      ],
      arrows: [
        {
          title: "Leadership Culture",
          text:
            "Our engemagents are designed to be collaborative and build consensus with all key stakeholders, resulting in cultural buy-in through a clear ROI driven business case.",
        },
        {
          title: "Validated Strategy",
          text:
            "Our strategies are rooted in your customer’s underserved needs and your business objectives. We work with you to prioritize for maximum ROI with minimum effort to get to market fast.",
        },
        {
          title: "Expert Digital Skillset",
          text:
            "We work with your internal teams and augment where needed with our veteran practice leaders and extensive resource pool to award winning digital skills to any project.",
        },
        {
          title: "Agile Execution",
          text:
            "We’re technology agnostic so we choose the right IT tech stack for your business ecosystems. Our trained teams and processes mitigate risk to succeed on time and under budget.",
        },
      ],
    },
    processExplained: {
      title: "How does this maximize ROI?\nWith Propane:",
      cards: [
        {
          icon: handIcon1,
          text:
            "You’re not alone in pitching the C-suite – your team & peers are invested with you.",
        },
        {
          icon: handIcon2,
          text:
            "You get to market faster with validated ideas for reduced risk and faster speed to value and returns.",
        },
        {
          icon: handIcon3,
          text:
            "Your teams are right-sized to reduce overhead, reducing overall investment.",
        },
        {
          icon: handIcon4,
          text:
            "Our teams know how to execute in coordination to optimize effort, and cost.",
        },
      ],
      secondTitle:
        "We’re invested in our clients because they’re invested in patient outcomes.",
      logoCaption: "Improving urgent care triage",
    },
    commitment: {
      title:
        "At Propane, we’re committed to creating experiences that lead to the best outcomes for our clients’ businesses and their customers well-being.",
    },
    final: {
      quotesTitle:
        "No matter where you are in any digital project, Propane can get you on the path to Parallel Alignment to maximize your digital ROI.",
      quotes: [
        {
          portrait: quotePortrait1,
          quote:
            "I recommend them for small or large budgets because they have been able to right-size scope any project.",
          name: "Alexandra Morehouse",
          title: "CMO, banner health",
        },
      ],
      scheduleTitle:
        "The first step is a free ROI digital assessment, to see how we can help.",
      scheduleCtaText: "Schedule Now",
      scheduleCtaLink: "/",
      scheduleSubtitle: "Click to schedule a time that is convenient for you",
      thankYouTitle:
        "Healthcare is hard but it doesn’t have to be with the right partner.",
      thankYouIcon: thankYouIcon,
      thankYouSubtitle:
        "Thank you for being a part of helping people be their happiest, healthiest selves.",
    },
  }
  const [contactVisible, setContactVisible] = React.useState(false)
  return (
    <React.Fragment>
      <SEO
        title={site?.siteTitle}
        description={site?.description?.description}
      />
      <Header showContact={() => setContactVisible(true)} />
      <HomeBlade data={data.home} />
      <QuoteBlade data={data.quote} />
      <CopyBlade data={data.copy} />
      <ReasonsBlade data={data.reasons} />
      <ProblemsBlade data={data.problems} />
      <TruthBlade data={data.truth} />
      <TransformationBlade data={data.transformation} />
      <ChallengesBlade data={data.challenges} />
      <SecretBlade data={data.secret} />
      <HeightsBlade data={data.heights} />
      <OutcomeBlade data={data.outcome} />
      <TopHealthcareBlade data={data.topHealthcare} />
      <ProcessBlade data={data.process} />
      <ProcessExplainedBlade data={data.processExplained} />
      <CommitmentBlade data={data.commitment} />
      <FinalBlade data={data.final} />
      <Modal visible={contactVisible} onClose={() => setContactVisible(false)}>
        <ContactForm />
      </Modal>
      <Footer showContact={() => setContactVisible(true)} />
    </React.Fragment>
  )
}

export default IndexPage
